<template>
    <div class="action-premium-end">
        <div class="title" v-html="$translate('ACTION_PREMIUM_END_TITLE')" />
        <div class="message" v-html="$translate('ACTION_PREMIUM_END_BODY')" />
        <button class="btn" @click="extend" v-html="$translate('ACTION_PREMIUM_END_BTN')" />
    </div>
</template>

<script>
export default {
    name: 'ActionPremiumEnd',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        user() {
            return this.content.user || {}
        },
        profile() {
            return this.chat.user.profile
        },
    },
    methods: {
        async extend() {
            this.$stackRouter.push({
                name: 'PgStorePage',
                props: {
                    productType: 'premium',
                    agent: { id: this.$store.getters.chat.agent_id },
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-premium-end {
    .profile-container,
    .question,
    .button-container {
        padding: 0 12px;
    }

    .title {
        color: black;
        font-size: 16px;
        margin-bottom: 12px;
    }

    .message {
        font-size: 14px;
        color: $grey-09;
        margin-left: 12px;
        margin-bottom: 16px;
    }

    .btn {
        height: 36px;
        border-radius: 8px;
        background: $purple-primary;
        color: white;
        font-size: 14px;
        width: calc(100% - 24px);
        margin-bottom: 12px;
        margin-left: 12px;
    }
}
</style>
